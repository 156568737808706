<template>
  <AlchemyPage
    v-if="data && 'pageLayout' in data"
    :key="data.id"
    :page="data"
  />
  <Product v-if="data && 'variants' in data" :key="data.id" :product="data" />
</template>

<script setup lang="ts">
  import { defineAsyncComponent } from "vue"

  import { deserialize } from "@alchemy_cms/json_api"
  import { productsInclude, pagesInclude } from "~/lib/includeParams"
  import { createCacheKey } from "~/utils/createCacheKey"

  const route = useRoute()
  const app = useNuxtApp()
  const { api } = useApi()

  const AlchemyPage = defineAsyncComponent(
    () => import("~/alchemy/alchemy_page.vue"),
  )

  const Product = defineAsyncComponent(
    () => import("~/components/Product/index.vue"),
  )

  const createdCacheKey = computed(() => createCacheKey(route))

  const { data, error } = await useAsyncData<AlchemyPage | SolidusProduct>(
    createdCacheKey.value,
    () => {
      const pathWithoutTrailingSlash = route.path.replace(/\/$/, "")
      return api(
        `/jsonapi/storefront_contents${pathWithoutTrailingSlash}.json`,
        {
          params: {
            preview_mode: app.$preview ? "true" : undefined,
            t: app.$preview ? Date.now() : undefined,
            pages_include: pagesInclude,
            products_include: productsInclude,
          },
        },
      )
    },

    {
      server: !app.$preview,
      transform: (data) => {
        const deserializedResult: SolidusProduct | AlchemyPage =
          deserialize(data)

        if (deserializedResult && "pageLayout" in deserializedResult) {
          return deserializedResult as AlchemyPage
        } else {
          return deserializedResult as SolidusProduct
        }
      },
    },
  )

  if (!data.value && error.value) {
    throw createError(error.value)
  }
</script>
