export const productsInclude = [
  "variants.images",
  "variants.volume_prices",
  "fragrance",
  "documents",
  "properties",
  "replacement_product",
  "related_products",
  "product_instruction",
  "essential_oil",
  "helpful_reviews",
  "helpful_reviews.response",
  "top_reviews",
  "top_reviews.response",
  "candle_container",
  "candle_wax.wax_applications",
  "candle_wick",
  "variants.kit_configuration",
].join(",")

export const pagesInclude = [
  "ancestors",
  "all_elements.ingredients.taxonomy.taxons",
].join(",")
